<script setup lang="ts">
export interface BaseSliderSlide {
  imageUrl: string;
  linkUrl?: string;
  linkTarget?: string;
  title?: string;
  description?: string;
}

export interface BaseSliderProps {
  slides: BaseSliderSlide[];
  playInterval?: number;
  inactivityInterval?: number;
  slideImageClass?: string;
  /**
   * If set to true - will run slides to autoplay with the interval specified by `playInterval`
   */
  hasAutoplay?: boolean;
  hasIndicator?: boolean;
  hasControls?: boolean;
  hasImageMask?: boolean;
}
const activeSlideIndex = ref(0);
const autoplayTimerId = ref<number>();
const inactivityTimerId = ref<number>();

const props = withDefaults(defineProps<BaseSliderProps>(), {
  playInterval: 5000,
  inactivityInterval: 3000,
  slideImageClass: "object-cover",
});
const hasControls = computed(() => props.hasControls && props.slides?.length > 1);
const hasIndicator = computed(() => props.hasIndicator && props.slides?.length > 1);

function moveSlides(slidesToMove: number, autoplay?: boolean) {
  const slidesNum = props.slides?.length - 1;
  const nextSlide = activeSlideIndex.value + slidesToMove;

  if (nextSlide > slidesNum)
    activeSlideIndex.value = 0;
  else if (nextSlide < 0)
    activeSlideIndex.value = slidesNum;
  else
    activeSlideIndex.value = nextSlide;

  if (props.hasAutoplay && !autoplay) {
    clearInterval(autoplayTimerId.value);
    clearTimeout(inactivityTimerId.value);

    inactivityTimerId.value = setTimeout(() => runAutoPlay(), props.inactivityInterval) as unknown as number;
  }
}

function runAutoPlay() {
  if (!props.hasAutoplay)
    return;

  autoplayTimerId.value = setInterval(() => moveSlides(1, true), props.playInterval) as unknown as number;
}

function hasImageMask(slide: BaseSliderSlide) {
  return props.hasImageMask && (Boolean(slide.title?.trim()) || Boolean(slide.description?.trim()));
}

onMounted(() => runAutoPlay());
onUnmounted(() => clearInterval(autoplayTimerId.value));
</script>

<template>
  <div class="baseSlider">
    <div class="baseSlides">
      <div
        v-for="(slide, slideIndex) in slides"
        :key="slideIndex"
        class="baseSlide "
        :class="{ baseSlide__active: activeSlideIndex === slideIndex, baseSlide__image_mask: hasImageMask(slide) }"
        :style="`--tw-translate-x: ${(slideIndex - activeSlideIndex) * 100}%;`"
      >
        <a v-if="slide.linkUrl && slide.linkTarget" :href="slide.linkUrl" :target="slide.linkTarget || '_blank'" class="w-full h-full">
          <BaseImage class="baseSlide__image" width="full" height="full" :src="slide.imageUrl" :alt="slide.imageUrl" :class="props.slideImageClass" />
        </a>
        <BaseImage v-else class="baseSlide__image" width="full" height="full" :src="slide.imageUrl" :alt="slide.imageUrl" :class="props.slideImageClass" />
        <div class="baseSlide__content">
          <h3 v-if="slide.title" class="text-heading-2" v-html="slide.title" />
          <div v-if="slide.description" class="text-body-medium mt-4" v-html="slide.description" />
        </div>
      </div>
    </div>

    <div v-if="hasIndicator" class="baseSliderIndicator">
      <div
        v-for="(_slide, index) in slides"
        :key="index"
        class="baseSliderIndicator__item"
        :class="{ baseSliderIndicator__item_active: activeSlideIndex === index }"
        @click="moveSlides(index - activeSlideIndex)"
      />
    </div>

    <template v-if="hasControls">
      <BaseButton
        class="baseSlider__button baseSlider__buttonBack"
        tag="div"
        type="icon"
        name="baseSlider-slide-back"
        @click="moveSlides(-1)"
      >
        <IconChevronLeft class="relative z-10" />
      </BaseButton>
      <BaseButton
        class="baseSlider__button baseSlider__buttonNext"
        tag="div"
        type="icon"
        name="baseSlider-slide-back"
        @click="moveSlides(1)"
      >
        <IconChevronRight class="relative z-10" />
      </BaseButton>
    </template>
  </div>
</template>

<style lang="scss">
.baseSlider {
  @apply relative w-full h-full overflow-hidden
  flex flex-1;

  .baseSlider__buttonBack {
    @apply left-6;
  }

  .baseSlider__buttonNext {
    @apply right-6;
  }

  .baseSlider__button {
    @apply w-10 h-10 sm:w-14 sm:h-14
    absolute z-30 top-1/2
    mx-auto rounded-full overflow-hidden
    flex items-center justify-center
    transition duration-100
    hover:shadow-lg;

    color: #fff;
    transform: translateY(-50%);

    &::before {
      @apply absolute w-full h-full z-0 opacity-75 rounded-full;

      content: '';
      background-color: #0f1624;
    }
  }

  .baseSliderIndicator {
    @apply absolute z-30 left-1/2 bottom-6 w-full max-w-[2/3] text-center flex justify-center items-center;

    color: var(--base-text-color);
    transform: translateX(-50%);
  }

  .baseSliderIndicator__item {
    @apply w-1.5 h-1.5 bg-white mx-2 transition rounded-full flex-shrink-0 hover:cursor-pointer;
  }

  .baseSliderIndicator__item_active {
    background-color: var(--base-primary-color);
  }
}

.baseSlides {
  @apply relative w-full overflow-hidden flex justify-center items-center;
}

.baseSlide {
  @apply w-full
  flex justify-center items-center
  duration-700 ease-in-out absolute inset-0 transition-all transform z-10;

  &.baseSlide__active {
    @apply translate-x-0 z-10;
  }

  .baseSlide__content {
    @apply absolute left-1/2 bottom-16 w-full max-w-[80%] text-center z-30;

    color: #ffffff;
    transform: translateX(-50%);
  }

  .baseSlide__image {
    @apply inline-block w-full h-full max-w-full max-h-full;
  }
}

.baseSlide__image_mask::before {
      @apply w-full h-full z-20 absolute top-0 left-0 right-0;
      content: '';
      background-image: linear-gradient(to bottom, rgba(15, 22, 36, 0.5), rgba(15, 22, 36, 0.5) 63%, #0f1624);
}
</style>
